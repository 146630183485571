import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/fuelling/Development/sappenin/ilpv4-dev-website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "interledger-ilpv4-sandbox"
    }}>{`Interledger (ILPv4) Sandbox`}</h1>
    <p><strong parentName="p">{`👋 Welcome to ilpv4.dev, the Interledger sandbox!`}</strong></p>
    <p>{`This website allows you to experience the future of payments using the Interledger Testnet (`}<inlineCode parentName="p">{`mainnet`}</inlineCode>{` coming soon).`}</p>
    <h2 {...{
      "id": "background"
    }}>{`Background`}</h2>
    <p>{`The Interledger Protocol (ILP) provides the foundation for the internet of Value. ILP is an open protocol that enables
sending money in any currency and any amount. The goal is to make sending value as fast, easy, and inexpensive as
sending information across the internet is today.`}</p>
    <p>{`Like routers on today's internet, Interledger connectors route packets of money across independent
payment networks. The open architecture and minimal protocol enable interoperability for any
value transfer system.`}</p>
    <p>{`Interledger is not tied to any one company, blockchain, or currency. We believe that
interoperability is critical to achieving the internet of value and envision a widely available
Interledger network allowing anyone to pay or get paid in the currency of their choice, nearly
instantaneously and at minimal cost.`}</p>
    <p>{`For more information, read more at `}<a parentName="p" {...{
        "href": "https://interledger.org"
      }}>{`interledger.org`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      